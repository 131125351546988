.popover{
    border: 2px solid $secondary;
    min-width: 210px;
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.1);
	@at-root [data-theme-version="dark"] & {
        background-color: $d-bg;
    }
    &-header{
        background: $secondary;
        color: $white;
        font-weight: 300;

        &::before{
            border-bottom: 0 !important;
        }
    }

    &-body{
        font-size: 0.75rem;
    }
	.popover-header{
		@at-root [data-theme-version="dark"] & {
			border-color: $dark-card;
		}
    }
	@include respond ('phone-land'){
		z-index:1;
	}
}

.bootstrap-popover-wrapper{
    .bootstrap-popover:not(:last-child){
        margin-right: 8px;
    }

    .bootstrap-popover{
        margin-bottom: .5rem;

        button:hover,button:focus{
            background: var(--primary);
            color: $white;
            box-shadow: none;
        }
    }
}

// .tooltip {
//     font-size: 15px;
// }


// .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
//     bottom: 0px;
//     border-top-color: $secondary;
// }

// .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
//     right: 0px;
//     border-left-color: $secondary;
// }

// .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
//     left: 0px;
//     border-right-color: $secondary;
// }

// .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
//     top: 0px;
//     border-bottom-color: $secondary;
// }

// .note-popover{
//     display: none;
// }

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    border-bottom-color: $secondary;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before{
    border-right-color: $secondary;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before{
	border-top-color: $secondary;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before{
	border-left-color: $secondary;
}

.note-popover{
    display: none;
}

.toltip-popover{
	.tooltip-inner{
		background: #ffffff;    
		padding: 0;
		border-top-left-radius: calc(0.3rem - 1px);
		border-top-right-radius: calc(0.3rem - 1px);
		border: 0.125rem solid $secondary;
		strong{
			color: #777777;
		}
	}
}
.popover-header{
    background: $secondary;
    border-top-left-radius: 0.1rem;
    border-top-right-radius: 0.1rem;
}