.loader {
    border: 5px solid #f3f3f3;
    /* Light grey */
    border-top: 5px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
}



@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.margin-3 {
    margin: 3rem;
}

.width-100 {
    width: 100% !important;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.wrapper .page-loader {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
}

.page-loader .loading {
    background: #030E52;
    width: 25px;
    height: 25px;
    border-radius: 50px;
    margin: 0 10px;
    animation: load 0.7s ease infinite;
}

.page-loader .loading.one {
    animation-delay: 0.3s;
}

.page-loader .loading.two {
    animation-delay: 0.4s;
}

.page-loader .loading.three {
    animation-delay: 0.5s;
}

.btn {
    border: none;
}

.text-red {
    color: #CA0505;
}

.text-blue {
    color: #025BFD;
}

.bg-red {
    background-color: #CA0505;
}

.bg-blue {
    background-color: #025BFD;
}

.bg-darkBlue {
    background-color: #030E52;
}

.bg-skyBlue {
    background-color: #00A3FF;
}

.bg-yellow {
    background-color: #FFAE1F;
}

.bg-yellow-light {
    background-color: #FFF9E6;
}

.bg-lavender {
    background-color: #F0F0F0;
}

.bg-blueGreen {
    background-color: #00B4B0;
}

.bg-Green {
    background-color: #258D00;
}

.bg-lightGreen {
    background-color: #A0D900;
}

.green-btn,
.blue-btn,
.yellow-btn,
.red-btn,
.grey-btn,
.red-disable-btn,
.yellow-disable-btn {
    background-color: #36AA00;
    color: #ffffff;
    border: none;
    outline: none;
    width: 80px;
    padding: 8px;
    font-size: 14px;
    border-radius: 5px;
}

.red-btn {
    background-color: #FF0000;
}

.red-disable-btn {
    background-color: #e26868;
}

.blue-btn {
    background-color: #030E52;
}

.yellow-btn {
    background-color: #FFAE1F;
}

.grey-btn {
    background-color: #DDDDDD;
}

.yellow-disable-btn {
    background-color: #FFAE1F;
    opacity: 0.6;
    cursor: not-allowed !important;
}


.content-body {
    background-color: #ffffff;
}

.header-right .header-border button {
    background-color: transparent !important;
    border: 1px solid #ffffff !important;
    font-size: 1rem !important;
    padding: 6px 15px !important;
}

.header-profile2 {
    padding-right: 30px;
}

.nav-control .is-active .nav-header {
    left: 4rem;
}

.deznav .metismenu {
    padding-top: 30px;
    padding-bottom: 50px;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .astroLogo img {
    width: 100%;
}

.help-desk {
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: #030E52;
}

.help-desk img {
    width: 30px;
}

.help-desk a {
    font-size: 1rem;
    color: #ffffff;
    padding-left: 10px;
}

@media only screen and (max-width: 63.9375rem) {
    [data-sidebar-style="mini"] .astroLogo {
        margin-top: 3.8rem;
    }

    [data-sidebar-style="mini"] .astroLogo img {
        width: 100%;
    }
}

@media only screen and (max-width: 47.9375rem) {
    .help-desk {
        bottom: 40px;
    }

    .deznav .metismenu {
        padding-bottom: 100px;
    }
}

/* dashboard */
.active-cardMain {
    padding: 1rem;
    margin: 3rem;
}

.active-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    color: #ffffff;
}

.active-cards .active-card {
    width: 32% !important;
    border-radius: 10px;
    padding: 20px 30px;
    cursor: pointer;
}

.order-history-cards {
    justify-content: center;
}

.order-history-cards .active-card {
    width: 25% !important;
    padding: 0 30px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.active-cards .active-card:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.active-cards .active-card .active-count p {
    font-size: 50px;
    margin-bottom: 5px !important;
    font-weight: 600;
}

.order-history-cards .active-card .active-count p {
    font-size: 40px;
}

.active-cards .active-card .active-icon {
    display: flex;
    align-items: center;
}

.active-cards .active-card .active-icon .icon {
    font-size: 50px;
}

.order-history-cards .active-card .active-icon .icon {
    font-size: 40px;
}

.active-cards .active-card .active-title p {
    font-size: 20px;
    margin-bottom: 0px !important;
}

.chartjs-render-monitor {
    height: 300px !important;
}

.statistic-chart {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 5px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.top-rating-dashboard .top-rating-title {
    background-color: #030E52;
    text-align: center;
    padding: 15px 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.top-rating-dashboard .top-raters {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 20px;
    height: 330px;
    overflow-y: scroll;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
}

.top-rater-card {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.top-rating-dashboard .top-raters .top-raters-profile {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.top-rating-dashboard .top-raters .top-raters-profile img {
    width: 100%;
    border-radius: 50%;
}

/* .top-rating-dashboard .top-raters .top-raters-details{
    width: 200px;
} */
.top-rating-dashboard .top-raters .top-raters-details h4,
.top-rating-dashboard .top-raters .top-raters-details p {
    margin-bottom: 5px !important;
}

.top-rating-dashboard .top-raters .profileStar span {
    font-size: 16px;
}

@media only screen and (max-width: 85rem) {
    .active-cards {
        gap: 10px;
    }
}

@media only screen and (max-width: 67rem) {

    .active-cards .active-card {
        width: 48% !important;
        padding: 15px 20px;
    }

    .order-history-cards .active-card {
        width: 30% !important;
        padding: 5px 20px;
    }

    .active-cards .active-card .active-count p,
    .active-cards .active-card .active-icon .icon {
        font-size: 35px;
    }

    .order-history-cards .active-card .active-icon .icon {
        font-size: 30px;
    }

    .active-cards .active-card .active-title p {
        font-size: 16px;
    }

    .order-history-cards .active-card .active-count p {
        font-size: 30px;
    }

    .ratingsMain {
        justify-content: center !important;
        gap: 3rem;
    }

    .top-rating-dashboard .top-raters .top-raters-details h4 {
        font-size: 1rem;
    }

    .top-rating-dashboard .top-raters .profileStar span {
        font-size: 14px;
    }
}

@media only screen and (max-width: 32rem) {
    .active-cardMain {
        margin: 0px;
    }

    .order-history-cards .active-card .active-icon .icon {
        font-size: 20px;
    }

    .order-history-cards .active-card .active-count p {
        font-size: 20px;
    }
}

/* Login */
.login-page {
    background-image: url(../images/dashboard/login-background.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-screen {
    background: #ffffff !important;
    color: #030E52 !important;
    padding: 40px !important;
    border-radius: 10px;
}

.login-screen .astro-logo {
    position: relative;
    height: 100px;
    display: flex;
    flex-direction: column;
}

.login-screen .astro-logo hr {
    margin-top: 10%;
    opacity: 0.75;
    border-top: 3px solid #030E52 !important;
}

.login-screen .astro-logo .astro-logo-img {
    position: absolute;
    left: 40%;
    bottom: 0;
    background-color: #ffffff;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
}

.login-screen .astro-logo .astro-logo-img img {
    width: 95%;
}

.login-screen input {
    width: 100%;
    height: 60px;
    font-size: 18px;
    border-radius: 5px;
    padding-left: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border: 1px solid rgba(0, 0, 0, 0.16);
}

.login-screen p {
    font-size: 18px !important;
    color: #030E52;
}

.login-screen .password {
    position: relative;
}

.login-screen .password .forgot a {
    font-size: 16px;
    text-decoration: underline;
    color: #025BFD !important;
}

.login-screen .eye-icon {
    position: absolute;
    top: 60px;
    right: 20px;
}

.login-screen .eye-icon .icon {
    font-size: 28px;
    color: #030E52;
    cursor: pointer;
}

.otp-button {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    width: 150px !important;
    height: 45px !important;
    box-shadow: none !important;
    border: none !important;
    border-radius: 5px;
}


@media (max-width:550px) {
    .otp-button {
        font-size: 16px !important;
    }
}



/* Astrologer & Users List */
.list-main .add-employee {
    display: flex;
    justify-content: end;
}

.list-main .add-employee button {
    padding: 5px 20px;
    border-radius: 10px;
    border: 1px solid #030E52;
    background-color: #030E52;
    color: #ffffff;
}

.list-main .add-employee button:hover {
    background-color: #030E52;
    color: #ffffff;
}

.list-title {
    padding: 20px 5px;
}

.list-main .list-data {
    overflow-x: auto;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.list-main .list-data .table-100 {
    width: 100% !important;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.list-main .list-data .table-140 {
    width: 140% !important;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.list-main .list-data .table-60 {
    width: 62% !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .list-main .list-data .table-60 {
    width: 65%;
}

.list-main .list-data table tr {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.list-main .list-data table th,
.list-main .list-data table td {
    padding: 15px;
    font-size: 18px;
    text-align: center;
}

.list-main .list-data table .orderCount {

    color: #000;
}

.list-main .list-data table .orderCount .icon {
    font-size: 28px;
    padding-right: 5px;
}

.list-main .list-data table .orderCount .success .icon {
    color: #FFAE1F;
}

.list-main .list-data table .orderCount .cancel {
    padding-left: 25px;
}

.list-main .list-data table .orderCount .cancel .icon {
    font-size: 24px;
    color: #030E52;
}

[data-sidebar-style="full"][data-layout="vertical"] .list-main .list-data .table-60 th,
[data-sidebar-style="full"][data-layout="vertical"] .list-main .list-data .table-60 td {
    font-size: 16px;
    padding: 10px;
}

.list-main .list-data table .view {
    color: #025BFD;
    text-decoration: underline;
    cursor: pointer;
}

.hide-list {
    display: none;
}


.list-canvas {
    width: 500px !important;
    margin-top: 4.5rem;
    background-color: #ffffff;
    left: auto !important;
}


.list-canvas .preview-card {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 5px;
    padding: 20px;
}

.list-canvas .preview-card h4,
.list-canvas .preview-card h5 {
    color: #030E52;
    text-decoration: underline;
    margin-bottom: 10px;
    font-weight: 600;
}

.list-canvas .preview-card .preview-data {
    font-size: 16px;
}

.list-canvas .preview-card .preview-data span {
    margin-right: 10px;
    font-weight: 600;
}


.list-canvas .call-audio audio {
    width: 100% !important;
}

.list-canvas .call-profile-img {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    width: 100px;
    height: 100px;
}

.list-canvas .call-profile-img img {
    width: 100%;
    border-radius: 50%;
}

.profileStar {
    display: flex;
    justify-content: center;
}

.colorStar {
    color: #FFAE1F;
}

.preview-btn {
    padding: 12px 20px;
    width: 150px !important;
    font-size: 16px;
}

.preview-download-btn {
    padding: 6px 10px !important;
    font-size: 12px !important;
}

.no-data {
    padding: 150px 0px;
}

.no-data p {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
}

.filter-nav .search-option {
    position: relative;
}

.filter-nav .search-option input {
    width: 300px;
    font-size: 16px;
    padding-left: 10px;
    border-radius: 5px;
}

.filter-nav .wallet-search-option input {
    width: 350px;
}

.filter-nav .search-option input::placeholder {
    font-size: 14px;
}

.filter-nav .search-option .search-btn {
    background-color: #030E52;
    display: flex;
    align-items: center;
    padding: 5px 15px !important;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.filter-nav .search-option .cancel-btn {
    color: #030E52;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.filter-nav .filter-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0 15px;
    border-radius: 5px;
    width: 120px;
}

.filter-nav .filter-btn .icon {
    font-size: 24px;
    color: #030E52;
}

.filter-nav .filter-btn p {
    margin-bottom: 0;
}

.filter-nav .filter-btn:hover {
    background-color: #FFAE1F;
    color: #ffffff;
    border: 1px solid #FFAE1F;
}

.filter-nav .filter-btn:hover .icon {
    color: #ffffff;
}

@media only screen and (max-width: 81.9375rem) {

    .list-main .list-data table th,
    .list-main .list-data table td {
        font-size: 16px;
        padding: 10px;
    }

    .list-canvas {
        width: 60% !important;
        top: 0 !important;
        border-top: 1px solid rgba(27, 31, 35, 0.15);
    }

    .hide-list {
        display: inline;
    }

    .list-main .list-data .table-60 {
        width: 100% !important;
    }

}

@media only screen and (max-width: 63.9375rem) {

    .list-main .list-data table th,
    .list-main .list-data table td {
        font-size: 14px;
        padding: 10px;
    }

    .list-canvas {
        width: 60% !important;
        top: 0 !important;
        border-top: 1px solid rgba(27, 31, 35, 0.15);
    }

    .hide-list {
        display: inline;
    }

    .list-main .list-data .table-60 {
        width: 100% !important;
    }

    .astrologer-list .approval-btn {
        width: 150px;
        padding: 10px;
    }

    .astrologer-list .list-main h2 {
        font-size: 22px;
    }

    .filter-nav .filter-btn {
        gap: 8px;
        padding: 0 8px;
        width: 100px;
    }

    .filter-nav .filter-btn .icon {
        font-size: 20px;
    }
}

@media only screen and (max-width: 47.9375rem) {
    .list-main .list-data .table-140 {
        width: 230% !important;
    }

    .list-main .list-data table th,
    .list-main .list-data table td {
        font-size: 12px;
        padding: 8px;
    }

    .list-canvas {
        width: 100% !important;
        top: auto !important;
        bottom: 0 !important;
        height: 80% !important;
        border-top: 1px solid rgba(27, 31, 35, 0.15);
    }

    .astrologer-list .list-main .col-sm-3,
    .employee-list .list-main .col-sm-3,
    .user-list .list-main .col-sm-3 {
        display: none;
    }

    .list-canvas .preview-card .preview-data {
        font-size: 14px;
    }

    .list-main .list-data .table-120 {
        width: 120% !important;
    }

    .list-main .list-data .table-180 {
        width: 180% !important;
    }

    .list-main .list-data .table-200 {
        width: 220% !important;
    }


    .list-main .list-data table .orderCount .cancel {
        padding-left: 15px;
    }

    .filter-nav {
        flex-wrap: wrap;
        width: 100%;
    }

    .filter-nav .search-option {
        width: 100%;
    }

    .filter-nav .search-option input {
        height: 35px;
        width: 100%;
    }

    .filter-nav .filter-btn {
        width: 48%;
        height: 35px;
    }

}

@media only screen and (max-width: 25rem) {
    .filter-nav .filter-btn {
        width: 47%;
    }
}

/* Astrologer Filter */
.filter-modal .modal-content {
    background-color: #ffffff !important;
}

.sortby-modal .modal-body {
    padding: 30px;
}

.filter-modal .modal-body {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 350px;
}

.sortby-modal .modal-header h4,
.filter-modal .modal-header h4 {
    margin-bottom: 0;
}

.filter-modal .filter-left {
    background-color: #FFF7E1;
    border-right: 1px solid #C9C9C9;
    padding: 10px 0px;
    height: 350px;
}

.filter-modal .filter-left .nav-item {
    border-bottom: 1px solid #C9C9C9;
}

.filter-modal .filter-left .nav-item .nav-link {
    color: #030E52;
    font-weight: 600;
}

.filter-modal .filter-left .nav-item .nav-link.active {
    background-color: transparent;
    font-weight: 700;
}

.filter-modal .filter-right {
    padding: 20px;
    overflow: scroll;
    height: 350px;
}

.filter-modal .filter-right::-webkit-scrollbar {
    display: none;
}

.filter-modal .tab-content>.tab-pane {
    display: none !important;
}

.filter-modal .tab-content>.active {
    display: block !important;
    display: flex !important;
    flex-direction: column !important;
    gap: 15px !important;
}

.sortby input {
    width: 22px;
}

.sortby input[type='radio'] {
    accent-color: #030E52;
}

.sortby input[type='radio']:before {
    border-color: #030E52;
}

.sortby p {
    font-size: 16px;
    color: #030E52;
    font-weight: 600;
    margin-bottom: 0;
}

.sortby input[type='checkbox'] {
    accent-color: #030E52;
    border-radius: 25px;
}

.filter-nav .date-filter .react-datepicker-wrapper {
    height: 100%;
}

.filter-nav .date-filter .react-datepicker__input-container {
    height: 100%;
    display: flex;
    align-items: center;
}

.filter-nav .date-filter .react-datepicker__input-container .react-datepicker__calendar-icon {
    width: 1.5em;
    height: 1.5em;
    color: #030E52;
}

.filter-nav .date-filter .react-datepicker__input-container input {
    height: 100%;
    border-radius: 5px;
    border: 1px solid #030E52;
    padding: 6px 25px 5px 30px;
}

@media only screen and (max-width: 47.9375rem) {
    .filter-modal .filter-left {
        height: 100% !important;
        background-color: transparent;
        border: none;
    }

    .filter-modal .filter-left .nav-pills {
        flex-direction: row !important;
    }

    .filter-modal .filter-right {
        height: 300px;
    }

    .filter-modal .filter-left .nav-item .nav-link.active {
        background-color: #030E52;
        color: #ffffff;
    }

    .filter-nav .date-filter {
        width: 200px;
    }

    .filter-nav .date-filter .react-datepicker-wrapper {
        width: 100% !important;
    }

    .filter-nav .date-filter .react-datepicker__input-container input {
        width: 100% !important;
    }
}

/* Astrologer Profile */
.astrologer-list .approval-btn:hover {
    background-color: #025BFD;
}

.astrologer-profile-btn {
    padding-right: 0 !important;
}

.astrologer-profile-btn button {
    min-width: 80px;
    background-color: transparent;
    border: 1px solid #030E52;
    padding: 10px 20px;
    border-radius: 25px;
}

.astrologer-profile-btn button:hover {
    background-color: #030E52;
    color: #ffffff;
}

.astrologer-profile-card {
    background-color: #FFF9E6;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    /* padding: 15px; */
    border-radius: 5px;
    padding: 0 !important;
}

.astrologer-profile-card h3,
.astrologer-professional-details h3 {
    color: #030E52;
    text-decoration: underline;
}

.astrologer-profile-card .astrologer-profile-img {
    width: 130px;
    height: 130px;
    display: flex;
    justify-content: center;
}

.astrologer-profile-card .astrologer-profile-img img {
    width: 100%;
    border-radius: 50%;
}

.astrologer-profile-card .astro-personal-details button {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: #025BFD;
    font-size: 16px;
}

.astrologer-profile-card .astro-personal-details .details p {
    font-size: 16px;
}

.astrologer-profile-card .astrologer-rating-card {
    display: flex;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.astrologer-profile-card .astrologer-rating-card .astrologer-review-count {
    display: flex;
    justify-content: flex-end;
    gap: 30px;
    text-align: center;
}

.astrologer-profile-card .astrologer-rating-card .astrologer-review-count p {
    margin-bottom: 0;
    font-size: 16px;
}

.astrologer-profile-card .astrologer-rating-card .astrologer-review-count .count {
    color: #025BFD;
    font-weight: 800;
}

.astrologer-profile-card .astro-personal-details .astrologer-online-status {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.astrologer-profile-card .astro-personal-details .astrologer-online-status h5 {
    margin-bottom: 0;
    color: #030E52;
    font-weight: 600;
    text-align: center;
}

.astrologer-profile-card .astro-personal-details .astrologer-online-status button {
    padding: 5px 15px;
    border-radius: 25px;
    background-color: #258D00;
    color: #ffffff;
    border: none;
    text-decoration: none;
    font-size: 10px;
}

.astrologer-professional-details {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 5px;
}

.astrologer-professional-details .preview-data,
.details .preview-data {
    font-size: 16px;
    font-weight: 600;
    color: #2C2C2C;
}

.astrologer-professional-details .preview-data span,
.details .preview-data span {
    margin-left: 10px;
    font-weight: 400;
}

@media only screen and (max-width: 63.9375rem) {
    .astrologer-profile-card .astrologer-profile-img {
        width: 100px;
        height: 100px;
    }

    .astrologer-profile-card .astro-personal-details {
        padding-left: 20px;
    }

    .astrologer-profile-card .astro-personal-details .details .col-lg-4,
    .astrologer-professional-details .col-lg-4,
    .astrologer-professional-details .col-lg-6 {
        width: 50% !important;
    }

    .astrologer-profile-card h3,
    .astrologer-professional-details h3 {
        font-size: 18px;
    }

    .astrologer-profile-card .astro-personal-details .details p,
    .astrologer-profile-card .astro-personal-details button,
    .astrologer-professional-details .preview-data,
    .details .preview-data {
        font-size: 14px;
    }
}

@media only screen and (max-width: 47.9375rem) {
    .astrologer-profile {
        padding: 1rem !important;
    }

    .astrologer-profile-card .astrologer-profile-card2 {
        flex-wrap: wrap !important;
        position: relative;
    }

    .astrologer-profile-card .col-lg-2 {
        width: 100% !important;
    }

    .astrologer-profile-card .col-lg-10 {
        width: 100% !important;
    }

    .astrologer-profile-card .astro-personal-details {
        padding-left: 0;
    }

    .details .preview-data span {
        display: block;
        margin-left: 0;
    }

    .astrologer-professional-details .col-lg-4,
    .astrologer-professional-details .col-lg-6 {
        width: 100% !important;
    }

    .astrologer-profile-card h3,
    .astrologer-professional-details h3 {
        font-size: 16px;
    }

    .astrologer-profile-btn {
       gap: 10px !important;
    }

    .astrologer-profile-btn button {
        max-width: 60px;
        padding: 5px 5px;
        font-size: 12px;
    }

    .astrologer-profile-btn .upload-btn {
          min-width: 100px;
    }

    .astrologer-profile-card .astro-personal-details button {
        position: unset;
        /* top: 10px; */
    }

    .astrologer-profile-card .astrologer-rating-card {
        flex-direction: column;
        gap: 10px;
    }

    .astrologer-profile-card .astrologer-rating-card .astrologer-review-count {
        justify-content: center;
    }

    .astrologer-profile-card .astrologer-rating-card .astrologer-review-count p {
        font-size: 14px;
    }
}

@media only screen and (max-width: 400px) {
    .astrologer-profile-btn {
        gap: 5px !important;
     }
 
     .astrologer-profile-btn button {
         min-width: 70px;
     }
 
}
/* New Astrologers */
.new-astroList-main {
    width: 50% !important;
}

.astrologer-Card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 49% !important;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    cursor: pointer;
}

.astrologer-fullCard {
    width: 100% !important;
    padding-left: 10px;
}

.astrologer-Card .change-date p {
    font-size: 10px;
    margin-bottom: 0px !important;
    text-align: end;
}

.astrologerCard-img {
    width: 32% !important;
}

.astrologerCard-img .astro-person-img {
    width: 180px;
    height: 180px;
}

.astrologerCard-img .astro-person-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.astrologerCard-content h6,
.astrologerCard-content p {
    font-size: 15px;
}


.astrologerCard-content .description,
.astrologerCard-content .timing,
.astrologerCard-content .lang-para {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.more-details {
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    color: #025BFD;
}

.btn-web {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;
}

.astrologer-details .astrologer-form {
    padding: 10px 0px;
}

.astrologer-details .astrologer-form .col-lg-6 {
    width: 48% !important;
}

.astrologer-details .astrologer-form .col-lg-4 {
    width: 32% !important;
}

.astrologer-details .astrologer-form .note {
    font-size: 12px;
}

.astrologer-details .astrologer-form input,
.astrologer-details .astrologer-form textarea,
.astrologer-details .astrologer-form select {
    width: 100%;
    height: 35px;
    border-radius: 5px;
    padding-left: 10px;
    font-size: 16px;
    border: 1px solid #000;
    color: #000;
    outline: none;
}

.astrologer-details .astrologer-form textarea {
    height: 100px;
}

.seo-details .astrologer-form textarea {
    height: 80px;
}

.astrologer-details .astrologer-form .react-tel-input .selected-flag {
    border: 1px solid #000;
    border-right: none;
}

.astrologer-details .astrologer-form .react-datepicker-wrapper {
    width: 100% !important;
}

.astrologer-details .astrologer-form .react-datepicker__calendar-icon {
    right: 5px;
    width: 1.5rem;
    height: 1.5rem;
}

.astrologer-details .astrologer-form .searchWrapper {
    padding: 5px;
    border: 1px solid #000;
}

.astrologer-details .astrologer-form .searchWrapper .chip {
    background-color: #030E52;
}

.astrologer-details .astrologer-form .searchWrapper input {
    margin-top: 0;
    border: none;
}

.highlightOption,
.multiSelectContainer li:hover {
    background-color: #030E52;
}

.css-b62m3t-container,
.css-13cymwt-control,
.css-1fdsijx-ValueContainer,
.css-qbdosj-Input {
    height: 35px;
}

.css-13cymwt-control {
    border: 1px solid #000 !important;
}

.css-166bipr-Input {
    margin: 0 !important;
    padding-top: 0 !important;
    color: #000 !important;
}

@media only screen and (max-width: 63.9375rem) {
    .new-astroList-main {
        width: 100% !important;
    }

    .astrologer-Card {
        width: 48% !important;
        display: flex;
        justify-content: space-between;
    }

    .astrologerCard-content h6,
    .astrologerCard-content p,
    .more-details {
        font-size: 12px;
    }

    .astrologerCard-content .description {
        height: 100%;
    }

    .yellow-btn,
    .green-btn {
        width: 70px;
        font-size: 12px;
        padding: 5px;
        border-radius: 5px;
    }

    .astrologerCard-img {
        width: 40% !important;
    }

    .astrologerCard-img .astro-person-img {
        width: 120px;
        height: 120px;
    }

    .astrologerCard-content p {
        margin-bottom: 5px !important;
    }

    .btn-web {
        margin-top: 10px;
    }

    .astrologer-details .astrologer-form .col-lg-4 {
        width: 48% !important;
    }
}

@media only screen and (max-width: 42rem) {
    .astrologer-Card {
        width: 100% !important;
    }

    .astrologer-details .astrologer-form .col-lg-4,
    .astrologer-details .astrologer-form .col-lg-12,
    .astrologer-details .astrologer-form .col-lg-6 {
        width: 100% !important;
    }

}

@media only screen and (max-width: 1300px) and (min-width: 1100px) {
    .astrologerCard-img .astro-person-img {
        width: 130px;
        height: 130px;
    }
}

/* Profile */
.profile-head {
    position: relative;
}

.profile .profile-head .profile-photo {
    position: relative;
}

.profile .profile-head .profile-photo:hover .profile-photo-update {
    opacity: 1;
}

.profile .profile-head .profile-photo-update {
    opacity: 0;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.profile .profile-head .profile-photo-update .profile-icon .icon {
    font-size: 24px;
    margin-bottom: 20px;
    color: #ffffff;
    cursor: pointer;
}

.profile-info .profile-details h4 {
    color: #030E52 !important;
    font-weight: 700;
    font-size: 18px;
}

.followers-card {
    position: absolute;
    right: 0;
    bottom: 7rem;
}

.followers-card .changePassword {
    color: #00A3FF;
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
}

.profile-update .profile-icon {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 5px;
}

.profile-update .icon {
    font-size: 24px;
    filter: brightness(0) saturate(100%) invert(6%) sepia(84%) saturate(7071%) hue-rotate(217deg) brightness(71%) contrast(116%);
    cursor: pointer;
}

.profile-about-me h4,
.profile-personal-info h4,
.profile-contact-info h4,
.profile-skills h4,
.profile-lang h4 {
    font-size: 20px;
    font-weight: 600;
    color: #030E52 !important;
    text-decoration: underline;
}

.profile-about-me p {
    font-size: 16px;
}

.profile-personal-info h5,
.profile-contact-info h5,
.profile-personal-info span,
.profile-contact-info span {
    font-size: 16px;
}

.profile-skills a {
    font-size: 16px !important;
    color: #2c2c2c !important;
    background-color: #FFF9E6 !important;
    padding: 10px !important;
    margin-right: 10px !important;
}

.profile-skills a:hover {
    border: 1px solid #FFAE1F !important;
}

.profile-lang a {
    font-size: 16px !important;
}

/* Order History */
/* .call-list-main{
    border-right: 1px solid rgba(0, 0, 0, 20%);
} */
.call-list-title {
    padding: 20px 5px;
}

.call-list .table-100 {
    width: 100% !important;
}

.call-list .table-60 {
    width: 62% !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .call-list .table-60 {
    width: 65%;
}

.call-list table tr {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.call-list table th,
.call-list table td {
    padding: 15px;
    font-size: 18px;
    text-align: center;
}

[data-sidebar-style="full"][data-layout="vertical"] .call-list .table-60 th,
[data-sidebar-style="full"][data-layout="vertical"] .call-list .table-60 td {
    font-size: 16px;
    padding: 10px;
}

.call-list table .view {
    color: #025BFD;
    text-decoration: underline;
    cursor: pointer;
}

.hide-list {
    display: none;
}


.call-canvas {
    width: 500px !important;
    margin-top: 4.5rem;
    background-color: #ffffff;
}

.call-canvas .offcanvas-body {
    display: flex;
    align-items: center;
}

.call-canvas .preview-card {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 5px;
    padding: 20px;
}

.call-canvas .preview-card .preview-data {
    font-size: 16px;
    margin-bottom: 5px !important;
    display: flex;
    gap: 10px;
}

.call-canvas .preview-card .preview-data span {
    font-weight: 600;
}

.call-canvas .preview-card .preview-data .status {
    width: 80px;
}

.call-canvas .call-audio audio {
    width: 100% !important;
}

.call-canvas .call-profile-img {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    width: 100px;
    height: 100px;
}

.call-canvas .call-profile-img img {
    border-radius: 50%;
}

.view-chat-btn button {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: #030E52;
    font-weight: 600;
}

.chat-history-header {
    background-color: #FFE799;
}

.chat-history-header h4 {
    font-weight: 600;
}

.chat-history-header .back-icon .icon {
    font-size: 24px;
    font-weight: 600;
    color: #030E52;
    cursor: pointer;
}

.chat-history-header button {
    display: none;
}

.chat-history-message {
    padding-bottom: 0 !important;
    padding-top: 40px !important;
}

.user-review {
    background-color: #FFF7E1;
}

.user-review .profileStar {
    justify-content: flex-start;
}

.offer-badge {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.offer-badge.radius-all {
    border-radius: 5px;
}

.offer-badge.radius-top {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.radius-bottom {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.offer-badge .offer-icon {
    font-size: 20px;
    color: #ffffff;
}

/* Chat Page */
.chat-canvas .offcanvas-header {
    background-color: #030E52;
    text-align: center;
    padding: 5px 20px !important;
}

.chat-canvas .offcanvas-header button {
    background-color: #ffffff;
    color: #030E52 !important;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    /* filter: brightness(0) invert(1); */
}

.chat-canvas .offcanvas-body {
    flex-direction: column;
    padding: 10px 0 !important;
    overflow-y: unset;
    position: relative;
    height: 70vh !important;
}

.chat-connect p {
    background-color: #FFE799;
    font-size: 14px;
    color: #097500;
    margin-bottom: 0;
    padding: 10px 20px;
    border-radius: 5px;
}

.chat-messages {
    height: 100% !important;
    overflow-y: scroll;
    padding: 0 10px;
    padding-bottom: 60px;
}

.chat-messages::-webkit-scrollbar {
    display: none;
}

.chat-person {
    width: 75%;
    font-size: 12px;
    padding: 0px 10px;
}

.chat-message p,
.chat-person p {
    margin-bottom: 0;
}

.chat-history-body {
    position: relative;
}

.chat-history-message {
    padding-bottom: 0 !important;
    padding-top: 40px !important;
}

.chat-history-body .chat-history-message {
    position: absolute;
    top: 0;
}

@media only screen and (max-width: 63.9375rem) {

    .call-list table th,
    .call-list table td {
        padding: 10px;
        font-size: 14px;
    }
}

@media only screen and (max-width: 47.9375rem) {
    .call-list {
        overflow-x: auto;
    }

    .call-list table th,
    .call-list table td {
        padding: 10px;
        font-size: 12px;
    }

    .call-list .table-100,
    .call-list .table-60 {
        width: 150% !important;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 5px;
    }

    .mobile-hide {
        display: none;
    }

    /* .hide-list{
        display: inline;
    } */
    .call-canvas .preview-card .preview-data {
        font-size: 12px;
    }

    .call-canvas {
        top: auto !important;
        right: auto !important;
        bottom: 0;
    }

    .call-canvas .offcanvas-header {
        padding: 15px 0px 0px 15px;
    }

    .call-canvas .offcanvas-body {
        display: inline;
        padding: 0px;
    }

    .call-canvas .call-audio audio {
        height: 35px;
    }
}

.chat-message {
    background-color: #FFE799;
    font-size: 16px;
    padding: 5px 15px;
    /* min-width: 10%; */
    max-width: 70%;
    border-radius: 8px;
    margin-bottom: 15px;
    overflow-wrap: break-word;
}

.chat-image {
    max-width: 300px;
    margin-bottom: 50px;
}

.chat-image img {
    width: 100%;
    height: 100%;
}

.chat-image .image-download {
    background-color: #FFE799;
    color: #030E52;
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
}

.chat-image .image-download p {
    font-size: 14px;
    margin-bottom: 0;
    padding-left: 10px;
    padding-bottom: 5px;
}

.chat-message p {
    margin-bottom: 0;
}

.chat-message span,
.chat-file .file-name span,
.chat-image .image-download span {
    font-family: sans-serif;
    font-size: 10px;
    display: flex;
    justify-content: flex-end;
    margin-right: -8px;
}

.chat-file .file-name span,
.chat-image .image-download span {
    margin-right: 10px;
    margin-bottom: 5px;
}

.chat-file {
    display: flex;
    gap: 10px;
    background-color: #030E52;
    color: #ffffff;
    border-radius: 5px;
    max-width: 60%;
    margin-bottom: 15px;
}

.chat-file .file {
    background-color: #030E52;
    padding: 10px;
    border-right: 1px solid #ffffff;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.chat-file .file .icon {
    font-size: 26px;
    color: #ffffff;
}

.chat-file .file-name p {
    margin-bottom: 0;
    font-size: 14px;
    padding: 10px;
    padding-left: 0;
}

.chat-canvas .user-name {
    text-align: left;
    margin-bottom: 0;
}

.chat-canvas .balance-time {
    font-size: 14px;
    margin-bottom: 0;
    color: #ffffff;
}

.chat-canvas .balance-time .time-count {
    color: #CA0505;
}

.chat-canvas .typing-status {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: #ffffff;
    margin-bottom: 3px;
}

.chat-canvas .typing-status.hide {
    opacity: 0;
}

.chat-history-body {
    position: relative;
}

.chat-history-body .chat-history-message {
    position: absolute;
    top: 0;
    width: 100%;
}

@media only screen and (max-width: 67.9375rem) {
    .chat-canvas {
        top: 0 !important;
        right: 0 !important;
    }

    .type-section {
        width: 100%;
    }

    .type-section .text-input {
        width: 80%;
    }

    .chart-img {
        width: 25px;
        height: 25px;
    }

    .chat-file .file-name p {
        font-size: 12px;
    }

    .chat-message span,
    .chat-file .file-name span,
    .chat-image .image-download span {
        font-size: 8px;
    }

    .chat-history-body .chat-history-message {
        position: unset;
        height: 300px !important;
        padding-top: 10px !important;
    }
}

@media only screen and (max-width: 47.9375rem) {
    .chat-canvas .modal-content {
        width: 80% !important;
    }
}

/* Changes Approval */

.approval-btn {
    border: none;
    outline: none;
    width: 180px;
    padding: 12px;
    font-size: 16px;
    border-radius: 20px;
}

.approval-btn:hover {
    background-color: #FFAE1F;
    color: #ffffff;
}

.changes-approval {
    padding: 0rem 3rem;
}

.changes-approval .changes-profile {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 5px;
    width: 49% !important;
}

.changes-approval .changes-profile .change-date p {
    font-size: 10px;
    margin-bottom: 0px !important;
    text-align: end;
}

.changes-approval .requester-profileImg {
    width: 90px;
    height: 90px;
}

.changes-approval .requester-profileImg img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.changes-approval .changes-profile .requester-profileDetail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding-left: 20px; */
}

.changes-approval .changes-profile .requester-profileDetail p {
    margin-bottom: 5px !important;
    font-size: 16px;
    color: #000;
}

.changes-approval .changes-profile .requester-profileDetail p span {
    font-weight: 500;

}

.changes-approval .view-btn {
    width: 100px;
    padding: 8px;
    background-color: #DBEFFF;
    color: #025BFD;
}

.back-btn {
    width: 50px;
}

.back-btn .icon {
    font-size: 24px;
}

.changes-approval .changes-profile .preview-data {
    font-size: 16px;
}

.changes-approval .changes-profile .preview-input {
    height: 30px;
}

.changes-approval .changes-profile .preview-data span {
    font-weight: 500;
    color: #000;
    margin-right: 10px;
}

.changes-approval .preview-profile-img {
    width: 120px;
    height: 120px;
}

.changes-approval .preview-profile-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

@media only screen and (max-width: 67.9375rem) {
    .approval-btn {
        width: 120px;
        font-size: 12px;
        padding: 8px;
    }

    .changes-approval {
        padding: 0rem 1rem;
    }

    .changes-approval .changes-profile {
        width: 48% !important;
    }

    .changes-approval .view-btn {
        width: 60px;
        padding: 5px;
    }

    .changes-approval .requester-profileImg {
        width: 65px;
        height: 65px;
    }

    .changes-approval .changes-profile .requester-profileDetail {
        padding-left: 10px;
        width: 70% !important;
    }

    .changes-approval .changes-profile .requester-profileDetail p {
        font-size: 14px;
    }

    .changes-approval .changes-profile .preview-data {
        font-size: 14px;
    }
}

@media only screen and (max-width: 47.9375rem) {
    .changes-approval .changes-profile {
        width: 100% !important;
    }
}

/* Bank details */

.details__wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 5px;
    padding: 20px;
}

.form__item {
    margin-bottom: 15px;
    width: 100% !important;
}

.details__wrapper .half-form {
    width: 49% !important;
}

.details__wrapper input,
.details__wrapper select,
.details__wrapper textarea,
.details__wrapper .search-wrapper,
.details__wrapper .react-datepicker-wrapper {
    display: block;
    width: 100% !important;
    border-radius: 5px;
    height: 40px !important;
    background-color: #F5F5F5;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border: none !important;
}

.details__wrapper .react-datepicker__calendar-icon {
    right: 5px;
    top: 0;
    width: 1.5rem;
    height: 1.5rem;
    filter: brightness(0) saturate(100%) invert(10%) sepia(34%) saturate(5849%) hue-rotate(228deg) brightness(85%) contrast(114%);
}

.details__wrapper .search-wrapper {
    height: 100% !important;
    display: flex;
    overflow-x: auto;
    padding: 0px !important;
}

.details__wrapper .search-wrapper input {
    width: 100% !important;
    height: 40px !important;
    margin-top: 0px !important;
    padding: 0px 10px;
}

.details__wrapper input,
.details__wrapper select {
    border: 1px solid grey;
}


.details__wrapper .search-wrapper::-webkit-scrollbar {
    display: none;
}

.details__wrapper textarea {
    background-color: #F5F5F5;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border: none !important;
    height: 100% !important;
}

.input__error input {
    border-color: red;
}

.input__error select {
    border-color: red;
}

.input__error textarea {
    border-color: red;
}

.error__feedback {
    color: red;
}



.details__wrapper .register-btn {
    background-color: transparent !important;
    color: #030E52 !important;
    border: none !important;
    box-shadow: none !important;
    font-size: 18px;
    font-weight: 700;
}

.details__wrapper .verify-btn,
.details__wrapper .verify-btn {
    width: 100px;
    height: 40px;
    font-size: 18px;
    border: none !important;
}

.details__wrapper .chip,
.details__wrapper .highlightOption,
.details__wrapper .option:hover {
    background-color: #030E52 !important;
}


.upload-img {
    border-radius: 5px;
}

.upload-img .astro-file-upload {
    display: flex;
    height: 60px !important;
    min-height: 60px !important;
    padding: 10px !important;
    position: relative;
}

.upload-img .astro-file-upload .fallback {
    z-index: 1000;
}

.upload-img .astro-file-upload input {
    opacity: 0;
    z-index: 1000;
}

.upload-img .fallback p {
    font-size: 12px;
    color: #CA0505;
    padding: 10px 30px;
}

.upload-img .astro-file-upload .file-icon,
.bank-file-upload button .file-icon {
    width: 30px;
    height: 30px;
    filter: brightness(0) saturate(100%) invert(9%) sepia(35%) saturate(6249%) hue-rotate(230deg) brightness(87%) contrast(113%);
}

.upload-img .astro-file-upload .upload-img-text {
    position: absolute;
    left: 50px;
}

.upload-img .astro-file-upload .upload-img-text p {
    margin-bottom: 0px !important;
    font-size: 12px;
    text-align: left;
}

.upload-img .astro-file-upload .upload-img-text .sub-note {
    font-size: 10px;
}

.upload-img .astro-file-upload .upload-img-text .file-name {
    font-size: 14px;
}


/* Wallet */
.wallet-buttons {
    position: relative;
}

.wallet-buttons .change-pin,
.wallet-buttons .view-download {
    all: unset;
    color: #00A3FF;
    text-decoration: underline;
    position: absolute;
    right: 0;
    top: 10px;
    font-size: 16px;
    cursor: pointer;
}

.wallet-buttons .change-pin {
    right: 150px;
}

.transaction-heading {
    background-color: #FFF7E1;
    box-shadow: none !important;
}

.transaction-data {
    box-shadow: none !important;
}

.transaction-data:nth-child(odd) {
    background-color: #F2F2F2;
}

.edit-wallet-modal .modal-header {
    background-color: #030E52;
    border-bottom: none;
}

.edit-wallet-modal .modal-header .btn-close {
    filter: brightness(1) invert(1);
    font-size: 18px;
    margin-top: 5px;
}

.edit-wallet-modal .modal-body {
    background-color: #030E52;
    padding: 0rem 2rem 3rem 2rem;
}

.edit-wallet-modal .pin-input-fields {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.edit-wallet-modal .pin-input-fields input {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    font-size: 24px;
    text-align: center;
}

.edit-wallet-modal .pin-response p {
    font-size: 18px;
    color: #CA0505;
    text-align: center;
}

.edit-wallet-modal .edit-wallet-screen {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 2rem;
    text-align: center;
}

.edit-wallet-modal .edit-wallet-screen h3 {
    color: #030E52;
    text-decoration: underline;
}

.edit-wallet-modal .edit-wallet-screen p {
    font-size: 16px;
}

.edit-wallet-modal .edit-wallet-screen p span {
    font-weight: 600;
}

.edit-wallet-modal .edit-wallet-screen input {
    width: 100px;
    height: 35px;
    padding-left: 10px;
    border-radius: 5px;
    font-size: 16px;
}

.edit-wallet-modal .edit-wallet-screen button {
    padding: 10px 20px;
    background-color: #FFAE1F;
    color: #ffffff;
    border: none;
    border-radius: 5px;
}

.edit-wallet-modal .change-pin-input {
    width: 150px;
    height: 50px;
    border-radius: 5px;
    padding: 2px 10px;
    font-size: 18px;
    text-align: center;
}

.edit-wallet-modal .change-pin-input::-webkit-outer-spin-button,
.edit-wallet-modal .change-pin-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.add-payment-modal .payment-input input {
    width: 100%;
    height: 35px;
    border-radius: 5px;
    padding-left: 5px;
}

@media only screen and (max-width: 47.9375rem) {
    .wallet-buttons .change-pin, .wallet-buttons .view-download{
        top: 60px;
        font-size: 14px;
    }

    .wallet-buttons .change-pin {
        right: 150px;
    }
    .wallet-buttons .view-download{
        right: 10px;
    }
}

/* Special Offers */

.offer-list-card {
    position: relative;
    border-radius: 5px;
    width: 48% !important;
    height: 250px;
}

.offer-list-card .img-fluid {
    width: 100% !important;
    height: 100% !important;
}

.offer-list-card .offer-overlay {
    position: absolute;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2553396358543417) 0%, rgba(0, 0, 0, 0.8995973389355743) 100%);
    color: #ffffff;
    height: 100%;
    width: 100%;
    bottom: 0;
    top: 0;
}

.offer-list-card .offer-overlay .offer-detail-left,
.offer-list-card .offer-overlay .offer-detail-right {
    position: absolute;
    bottom: 20px;
}

.offer-list-card .offer-overlay .offer-detail-right {
    justify-content: flex-end;
    right: 20px;
}

.offer-list-card .offer-overlay .offer-badge {
    background-color: #FFAE1F;
    width: 60%;
    height: 40px;
    clip-path: polygon(100% 0%, 90% 49%, 100% 100%, 0 100%, 0% 50%, 0 0);
    margin-bottom: 20px;
}

.offer-list-card .offer-overlay .offer-badge p,
.offer-list-card .offer-overlay .offer-detail h5,
.offer-list-card .offer-overlay .offer-detail p {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
    padding-left: 20px;
    color: #ffffff;
}

.offer-list-card .offer-overlay .offer-detail button {
    font-size: 12px;
    text-decoration: underline;
    background-color: transparent;
    border: none;
    color: #ffffff;
    padding-left: 20px;
}

.offer-list-card .offer-overlay .offer-btn {
    background-color: #ffffff;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
}

.offer-list-card .offer-overlay .offer-btn .icon {
    color: #030E52;
    font-size: 20px;
}

.offer-add-btn button {
    background-color: #FFAE1F;
    color: #ffffff;
    border: none;
    padding: 10px 25px;
    border-radius: 25px;
    cursor: pointer;
}

.add-offer-card {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.add-offer-card .upload-img .astro-file-upload {
    height: 100px !important;
}

.add-offer-card .upload-img .astro-file-upload .fallback,
.add-offer-card .upload-img .astro-file-upload .fallback input {
    width: 100% !important;
    height: 100% !important;
}

.offer-btns button {
    padding: 5px 10px;
    border-radius: 5px;
    border: none;
}

.offer-btns .publish {
    background-color: #258D00;
    color: #ffffff;
}

.offer-btns .unpublish {
    background-color: #FF0000;
    color: #ffffff;
}

.offer-btns .offer-edit {
    background-color: #FFAE1F;
    color: #ffffff;
}

.offer-btns .offer-delete {
    border: 1px solid #030E52;
}

.custom-react-select .css-1nmdiq5-mrnu {
    z-index: 1000 !important;
}

.add-blog-card .upload-img .astro-file-upload {
    height: 60px !important;
}

@media only screen and (max-width: 47.9375rem) {
    .offer-list-card{
        width: 100% !important;
    }
}

/* Reviews & Ratings */
.review-card {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 15px;
}

.review-card .review-user-profile {
    width: 100px;
    height: 100px;
}

.review-card .review-user-profile img {
    border-radius: 50%;
}

.review-card .review-date {
    margin-bottom: 0;
    font-size: 10px;
}

.review-card .review-astro-card {
    background-color: #F0EFEF;
    padding: 8px;
    border-radius: 5px;
}

.review-card .review-astro-card .review-astro-img {
    width: 50px;
    height: 50px;
}

.review-card .review-astro-card .review-astro-img img {
    width: 100%;
    border-radius: 50%;
}

.review-card .review-astro-card p {
    margin-bottom: 0;
}

.review-card .review-btn {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 1rem;
}

.review-card .review-btn button {
    background-color: transparent;
    padding: 5px 15px;
    border-radius: 5px;
}

.review-card .review-btn .accept {
    border: 1px solid #258D00;
    color: #258D00;
}

.review-card .review-btn .accept:hover {
    background-color: #258D00;
    color: #ffffff;
}

.review-card .review-btn .reject {
    border: 1px solid #FFAE1F;
    color: #FFAE1F;
}

.review-card .review-btn .reject:hover {
    background-color: #FFAE1F;
    color: #ffffff;
}

.review-card .review-details {
    display: flex;
    justify-content: center;
}

.review-card .review-details .review-res-userDetail {
    display: none;
}

@media only screen and (max-width: 42rem) {
    .reviewRow {
        margin: 0.5rem;
    }

    .review-card {
        flex-direction: column;
    }

    .review-card .review-details {
        display: flex;
    }

    .review-card .review-user-detail {
        display: none;
    }

    .review-card .review-details .review-res-userDetail {
        display: block;
        width: 100%;
        padding-left: 15px;
    }

    .review-card .review-user-profile {
        width: 80px;
        height: 80px;
    }

    .review-card .review-user-profile img {
        width: 100%;
        max-width: 100%;
    }

    .review-card .review-bottom-card {
        flex-direction: column;
        gap: 15px;
    }
}


/* Employee */
.employee-form .half-form .error__feedback {
    margin-bottom: 0;
}

.employee-form .form__item {
    margin-bottom: 1.5rem;
}

@media only screen and (max-width: 47.9375rem) {
    .employee-form {
        padding: 10px;
    }

    .employee-form .col-sm-6 {
        width: 100% !important;
    }

    .upload-img .astro-file-upload .upload-img-text .sub-note {
        font-size: 8px;
    }
}


/* Blogs */

.Cms-selecter select {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    padding: 10px;
}

.add-offer-card table .blog-titleData {
    width: 300px;
}

.add-offer-card table .view-details a {
    color: #00A3FF !important;
    text-decoration: underline !important;
}

.blog-tabs .nav-link {
    width: 150px;
    color: #030E52;
}

.blog-tabs .nav-link.active {
    font-weight: 600;
}

/* Support Chat */
.support-chat {
    height: 65vh;
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
}

.support-chat .helf,
.support-chat .chat-screen {
    padding: 0;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.support-chat .helf .nav-tabs .nav-item {
    width: 50%;
}

.support-chat .helf .nav-tabs .nav-link {
    width: 100%;
}

.support-chat .chat-screen {
    padding-bottom: 40px;
}

.support-chat .title {
    background-color: #004EDD;
    text-align: center;
    padding: 5px 0;
    position: relative;
}

.support-chat .title .icon {
    position: absolute;
    left: 20px;
    top: 8px;
    font-size: 24px;
    color: #FFFFFF;
}

.support-chat .title h5 {
    color: #FFFFFF;
}

.support-chat .title button {
    border-radius: 5px;
}

.support-chat .chat-screen .chat .user-details {
    width: 100%;
    display: flex;
    position: absolute;
    top: 0;
    background-color: #ffffff;
    justify-content: center;
    gap: 10px;
    padding: 10px;
}

.support-chat .chat-screen .chat {
    position: relative;
    background-color: #F0F0F0;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.support-chat .chat-screen .chat .type-section {
    display: flex;
    position: absolute;
    bottom: 0;
    background-color: #004EDD;
    justify-content: center;
    gap: 10px;
    padding: 10px;
}

.support-chat .chat-screen .chat .type-section .text-input {
    width: 85%;
    position: relative;
    display: flex;
}

.support-chat .chat-screen .chat .type-section .text-input textarea {
    width: 100%;
    height: 30px;
    min-height: 30px;
    max-height: 100px;
    border-radius: 25px;
    border: none;
    padding-left: 15px;
    padding-right: 40px;
    resize: none;
    font-size: 14px;
}

.support-chat .chat-screen .chat .type-section button {
    background-color: transparent;
    color: #FFFFFF;
    border: none;
    outline: none;
}

.support-chat .chat-screen .chat .type-section button .icon {
    font-size: 26px;
}

.support-chat .chat-screen .chat .type-section .text-input button {
    position: absolute;
    right: 5px;
    bottom: 5px;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
}

.support-chat .chat-screen .chat .type-section .text-input button .icon {
    color: #030E52;
}

.support-chat .helf .helf-inside {
    height: 92%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.support-chat .helf .ticket {
    display: flex;
    justify-content: space-between;
    padding: 10px 25px !important;
    font-size: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

.support-chat .helf .ticket p {
    margin-bottom: 0;
}

.support-chat .helf .ticket .date {
    font-size: 11px;
}

.support-chat .helf .ticket .status {
    font-weight: 700;
}

.support-chat .helf .ticket .status.open {
    color: #00A611;
}

.support-chat .helf .ticket .status.pending {
    color: #FFAE1F;
}

.support-chat .helf .ticket .status.close {
    color: #FF2020;
}

.chat-view-screen {
    display: flex;
    flex-direction: column;
    height: 65vh;
    overflow-y: scroll;
}

.chat-view-screen::-webkit-scrollbar {
    display: none;
}

.chat-connect {
    margin-bottom: 15px;
}

.chat-connect p {
    background-color: #FFE799;
    font-size: 14px;
    color: #097500;
    margin-bottom: 0;
    padding: 10px 20px;
    border-radius: 5px;
}

.chat-message {
    background-color: #FFE799;
    font-size: 16px;
    padding: 5px 15px;
    /* min-width: 10%; */
    max-width: 70%;
    border-radius: 8px;
    margin-bottom: 15px;
    overflow-wrap: break-word;
}

.chat-image {
    max-width: 400px;
    margin-bottom: 40px;
}

.chat-image img {
    width: 100%;
    height: 100%;
}

.chat-image .image-download {
    background-color: #FFFFFF;
    color: #030E52;
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
}

.chat-image .image-download p {
    font-size: 14px;
    margin-bottom: 0;
    padding-left: 10px;
    padding-bottom: 5px;
}

.chat-message p {
    margin-bottom: 0;
}

.chat-message span,
.chat-file .file-name span,
.chat-image .image-download span {
    font-family: sans-serif;
    font-size: 10px;
    display: flex;
    justify-content: flex-end;
    margin-right: -8px;
}

.chat-file .file-name span,
.chat-image .image-download span {
    margin-right: 10px;
    margin-bottom: 5px;
}

.quick-recharge {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 3rem !important;
}

.quick-recharge button {
    border-color: #319F43;
    color: #319F43;
    width: 60px;
    padding: 5px;
}

.chat-file {
    display: flex;
    gap: 10px;
    background-color: #030E52;
    color: #FFFFFF;
    border-radius: 5px;
    max-width: 50%;
    margin-bottom: 15px;
}

.chat-file .file {
    background-color: #030E52;
    padding: 10px;
    border-right: 1px solid #FFFFFF;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.chat-file .file .icon {
    font-size: 26px;
    color: #FFFFFF;
}

.chat-file .file-name p {
    margin-bottom: 0;
    font-size: 14px;
    padding: 10px;
    padding-left: 0;
}

.chat-waiting-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chat-waiting-modal h4 {
    font-family: sans-serif;
    color: #FFAE1F;
    font-weight: 800;
    text-align: center;
}

.chat-waiting-modal .process {
    font-size: 20px;
    font-weight: 600;
}

.chat-waiting-header {
    border-bottom: unset;
}

.extent-time-disable button {
    opacity: 0.7;
}

@media (max-width:550px) {
    .support-chat .title .icon {
        left: 10px;
    }
}

/* Earmings */
.blance-card {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 30px;
    border-radius: 5px;
}

.blance-card p {
    font-size: 18px;
    margin-bottom: 0px;
    color: rgba(0, 0, 0, 0.4);
    font-weight: 700;
}

.blance-card .avail-balance {
    font-size: 20px;
    color: #000;
    font-weight: 700;
}

.blance-card .avail-balance span {
    color: #258D00;
}

.transaction-table {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 5px;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.transaction-table table {
    width: 100% !important;
}

.transaction-table th,
.transaction-table tr {
    width: 180px;
    height: 60px;
    text-align: center;
}

.transaction-table .transaction-heading {
    background-color: #FFF7E1;
    line-height: 50px;
    padding: 10px;
}

.transaction-table .transaction-data:nth-child(odd) {
    background-color: #F2F2F2;
}

.payment-success {
    color: #00A611;
}

.payment-failed {
    color: #FF3636;
}

.no-data {
    margin: 60px 0px;
}

.no-data p {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    color: #B8B8B8;
}

.earnings-filter .react-datepicker__view-calendar-icon input {
    height: 36px;
    width: 100%;
}

@media (max-width:950px) {

    .transaction-table th,
    .transaction-table tr {
        font-size: 13px;
    }

    .transaction-table .transaction-heading {
        line-height: 25px;
    }
}

@media (max-width:550px) {

    .transaction-table {
        overflow-x: auto;
    }

    .transaction-table table {
        width: 200% !important;
    }

    .transaction-table .table-270 {
        width: 270% !important;
    }

    .blance-card .avail-balance {
        font-size: 14px;
    }

    .blance-card p {
        font-size: 12px;
    }

    .transaction-table th,
    .transaction-table td {
        font-size: 12px;
    }

    .blance-card {
        padding: 15px;
    }

    .earnings-filter .col-sm-6 {
        width: 50% !important;
    }
}

/* Report */
.report-table {
    height: 500px;
    overflow-x: auto;
    scrollbar-color: #030E52 #F2F2F2;
}

.extent-table-min table {
    width: 120% !important;
}

.extent-table-center table {
    width: 150% !important;
}

.extent-table-max table {
    width: 250% !important;
}


/* Custom Pagination */
.custom-pagination {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 20px 0;
}

/* .custom-pagination li {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid gray;
    border-radius: 5px;
    cursor: pointer;
} */
.custom-pagination li a {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid gray;
    border-radius: 5px;
    cursor: pointer;
}

.custom-pagination li.selected a,
.custom-pagination li a:hover {
    background-color: #030E52;
    border: 1px solid #030E52;
    color: #ffffff;
}

.custom-pagination .break a {
    border: none;
    font-size: 16px;
}

/* .custom-pagination .previous,
.custom-pagination .next,
.custom-pagination .break {
    border: none;
    font-size: 16px;
}

.custom-pagination .previous,
.custom-pagination .next {
    width: 100px;
    height: 35px;
}

.custom-pagination .previous:hover,
.custom-pagination .next:hover{
   background-color: transparent;
   border: none;
   color: #030E52;
}
.custom-pagination .previous:hover a,
.custom-pagination .next:hover a{
    color: #030E52;
    font-weight: 700;
} */

@media only screen and (max-width: 47.9375rem) {
    .custom-pagination li a {
        width: 25px;
        height: 25px;
        font-size: 12px;
    }
}


/* Notification */
.notification-list {
    position: relative;
    padding: 20px;
    border-radius: 10px;
    background-color: #F2F2F2;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    cursor: pointer;
}

.notification-list.not-seen {
    background-color: #FFF7E1;
    font-weight: 700;
    /* border: 1px solid #000; */
}

.notification-list.not-seen h6 {
    font-weight: 700;
}

.notification-list p {
    font-size: 14px;
    margin-bottom: 0px;
    word-break: break-all;
}

.notification-list .notify-date {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 12px;
}

.create-notification textarea {
    height: 100px !important;
}

.create-notification select {
    appearance: auto !important;
}

/* Reports */
.report-filter .react-datepicker-wrapper,
.report-filter .react-datepicker__input-container {
    width: 100%;
    height: 2.7rem;
}

.report-filter .report-date-picker {
    width: 100%;
    height: 100%;
    border: 1px solid #CCCCCC !important;
    border-radius: 5px;
}

.report-filter .react-datepicker__calendar-icon {
    padding-top: 1rem;
}

.blance-card .file-name {
    width: 180px;
    height: 30px;
    border-radius: 5px;
    padding-left: 5px;
}

.blance-card .export-button {
    font-size: 16px;
}

.blance-card .export-button .export-icon {
    font-size: 18px;
}

.blance-card .view-download a {
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
}