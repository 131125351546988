[data-theme-version="transparent"] {
    
    .header {
        background-color: rgba(0,0,0,0.15);
    }
    

    .header-left {
        input {
            border-color: $d-border;
            color: $white;

            &:focus {
                box-shadow: none;
                border-color:var(--primary);
            }

            &::placeholder {
                color: $white;
            }
        }
    }


    .header-right {
        .dropdown {
            .nav-link {
                &:hover {
                    color: $white;
                }
            }
        }

        .notification_dropdown {
            .dropdown-item {
                a {
                    color: $white;
                }
            }
        }
    }

}